import {
    useReducer,
    useEffect,
    useCallback,
    useContext,
    useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import useFetchData from "../../api/apiHelpers";
import api from "../../api/api";
import { AppContext } from "../App";
import { prepBarData } from "./helpers";

const PLAYER_AT_KIOSK_DELAY = 300;

export const useGKKData = (id: string) => {
    const handler = useFetchData();
    const history = useHistory();
    const location = useLocation();
    const [keyboardQuery, setKeyboardQuery] = useState("");
    const [progressBarData, setProgressBarData] = useState<any>([]);
    const {
        dispatchAppData,
        appData: {
            logged,
            loginData,
            infoData,
            networkError,
            cardAtKioskResponding,
            successRoute,
            failureRoute,
            RFIDSimulateTrigger,
            reactToRFID,
            levels,
            tokens,
            premiums,
            animatorCameScreen,
            animatorDeltaPointsTrigger,
         //   progressBarPrizesData,
        },
    } = useContext(AppContext);
    const [kioskInfo, dispatch] = useReducer(
        (state: object, payload: any) => ({ ...state, ...payload }),
        {
            RFIDInput: "",
            RFIDQuery: "",
        }
    );

    const { RFIDInput } = kioskInfo;

    const handleKeyboardInput = useCallback((event: any) => {
        setKeyboardQuery((text) => (text += event.key));
    }, []);

    const handleInputEventCallback = useCallback(() => {
        window.addEventListener("keypress", handleKeyboardInput);
        return () => {
            window.removeEventListener("keypress", handleKeyboardInput);
        };
    }, [handleKeyboardInput]);

    useEffect(() => {
        handleInputEventCallback();
    }, [handleInputEventCallback]);

    const GoToScreen = (slug: string) => {
        dispatchAppData({ gifTrigger: true });
        setTimeout(() => {
            history.push(location.pathname + slug);
        }, 200);
    };

    const scanBand = () => {
        if (RFIDInput === "88888888") {
            window.close();
            return;
        }
        if (
            typeof progressBarData.length === "undefined" ||
            progressBarData.length === 0
        ) {
            window.location.reload();
            return;
        }
        dispatchAppData({ cardAtKioskResponding: true });
        handler(async () => {
            const {
                data: {
                    data: [element],
                },
            } = infoData;

           // console.log("INFO DATA: ", infoData?.data?.data[0]?.id)

            const { id, hopperCOM, description } = element;
            let isTokenBox = "0";

            if (hopperCOM !== null) {
                isTokenBox = "1";
            }

            const response = await api
                .cardAtkiosk(
                    loginData.data.token,
                    isTokenBox,
                    id,
                    //'123456789',
                    RFIDInput,
                    isTokenBox,
                    animatorCameScreen ? "1" : "0",
                    // "0"
                    infoData?.data?.data[0]?.id === "37" ? "1" : "0"
                )
                .catch((error) => {
                    dispatchAppData({ networkError: true });
                    if (description && description.toLowerCase().includes("pionowy")) {
                        GoToScreen("/kiosk-error-vertical");
                    } else {
                        GoToScreen("/kiosk-error");
                    }
                });

                console.warn(response)

          

            if (response?.status && response.status === 200) {
               
                const playerInfoData = await api.getPlayerInfo(loginData.data.token, response.data.data.playerprofile_id)
       
                dispatchAppData({ cardAtKioskResponding: false });
       
                if (response.data.message !== "Player not found") {
                    dispatchAppData({ testPlayerAtKiosk: response });

                    const playerStats = playerInfoData?.data?.data[0]
   
                    dispatchAppData({
                        playerStats:{
                            speed: playerStats.totalpoints_1 ?? 0,
                            climbing: playerStats.totalpoints_2 ?? 0,
                            jumping: playerStats.totalpoints_3 ?? 0,
                            agality: playerStats.totalpoints_4 ?? 0,
                            flair: playerStats.totalpoints_5 ?? 0,
                        }
                    })
                    setKeyboardQuery("");
                    dispatch({ RFIDInput: "" });

                    const {
                        data: {
                            data: [element],
                        },
                    } = infoData;

                    const { pointsdelta, coachactivation, coachPoints } =
                        element;

                    const {
                        
                        data: {
                            data: {
                                PlayerPoints,
                                canEnter,
                                secondsToEnterKiosk,
                                premium,
                                level_id,
                                isanimator,
                                group_id,
                                groupName,
                                noofplayers,
                                pointsperplayer,
                                totalpoints,
                            },
                        },
                    } = response;

                    const animatorAtKiosk =
                        parseInt(isanimator) === 1 ? true : false;

                    if (animatorAtKiosk === true) {
                        let screenName = "";
                        if (group_id && parseInt(group_id) > 0 && groupName) {
                            const totalGroupPoints =
                                parseInt(noofplayers) *
                                parseInt(pointsperplayer);
                            if (parseInt(totalpoints) >= totalGroupPoints) {
                                screenName = "/birthday-congrats";
                            } else {
                   
                                screenName = "/birthday-points-missing";
                            }
                        } else {
                            screenName = animatorCameScreen
                                ? "/animator-away"
                                : "/animator-at-kiosk";
                            dispatchAppData({
                                animatorCameScreen: !animatorCameScreen,
                                animatorDeltaPointsTrigger: true,
                            });
                        }
                        GoToScreen(screenName);
                        return;
                    }

                    let deltaPoints = parseInt(pointsdelta);

                    if (
                        animatorCameScreen &&
                        coachactivation &&
                        parseInt(coachactivation) > 0 &&
                        coachPoints
                    ) {
                        deltaPoints = parseInt(coachPoints);
                    }

                    let playerStartPoints = parseInt(PlayerPoints) - deltaPoints;
                    playerStartPoints =
                        playerStartPoints < 0 ? 0 : playerStartPoints;

                    if (
                        premium &&
                        typeof premium === "object" &&
                        (!Array.isArray(premium) || premium?.length > 0)
                    ) {
                        const formula: string = premium.premium_formula;
                        const premiumType: number = parseInt(
                            premium.premium_whenapplied
                        );
                        if (premiumType === 1) {
                            const derPoints = parseInt(formula.slice(1));
                            if (formula[0] === "+") {
                                playerStartPoints -= derPoints;
                                deltaPoints += derPoints;
                            } else if (formula[0] === "*") {
                                playerStartPoints -= deltaPoints * derPoints;
                                deltaPoints += deltaPoints * derPoints;
                            } else {
                                console.log("WRONG PREMIUM FORMULA");
                            }
                        }
                    }

                    const tempPlayerLevelMinusOne =
                        parseInt(level_id) > 12
                            ? parseInt(level_id) - 1
                            : parseInt(level_id);
                    dispatchAppData({
                        startPoints: playerStartPoints,
                        playerDeltaPoints: deltaPoints,
                        finishPoints: PlayerPoints,
                        playerLevel: tempPlayerLevelMinusOne,
                    });

                    if (secondsToEnterKiosk && secondsToEnterKiosk > 0) {
                        dispatchAppData({
                            timeLeftToPlay: secondsToEnterKiosk,
                        });
                    }

                    if (canEnter === 0) {
                        GoToScreen(failureRoute);
                    } else {
                        GoToScreen(successRoute);
                    }
                } else {
                    if (description && description.toLowerCase().includes("pionowy")) {
                        GoToScreen("/band-error-vertical");
                    } else {
                        GoToScreen("/band-error");
                    }
                }
            }
        });
    };

    const handleSetRFIDInputCallback = useCallback((value) => {
        if (value.length !== 0) {
            let input = value.replace("Enter", "");
            dispatch({ RFIDInput: input });
        }
    }, []);

    useEffect(() => {
        if (logged === false && id !== "") {
            handler(async () => {
                const response = await api.login(id).catch((error) => {
                    dispatchAppData({ networkError: true });
                    GoToScreen("/kiosk-error");
                });
                dispatchAppData({ loginData: response });
            });
        }
    }, [dispatchAppData, id]);

    useEffect(() => {
        if (logged === false && networkError === false) {
            if (typeof loginData.data !== "undefined") {
                handler(async () => {
                    const info = await api.getPointINFO(
                        loginData.data.token,
                        loginData.data.name
                    );
                    dispatchAppData({ infoData: info });
                    dispatchAppData({
                        deltaPoints: info.data.data[0].pointsdelta,
                    });
                });
                handler(async () => {
                    const tokens = await api.getTokens(loginData.data.token);
                    dispatchAppData({ tokens: tokens });
                });
                handler(async () => {
                    const premiums = await api.getPremiums(
                        loginData.data.token
                    );
                    dispatchAppData({ premiums: premiums });
                });
                handler(async () => {
                    const levels = await api.getLevels(loginData.data.token);
                    dispatchAppData({ levels: levels, logged: true });
                });
            }
        }
    }, [loginData, dispatchAppData]);

    useEffect(() => {
        if (animatorDeltaPointsTrigger === true) {
            if (logged === true && networkError === false) {
                if (typeof loginData.data !== "undefined") {
                    handler(async () => {
                        const info = await api.getPointINFO(
                            loginData.data.token,
                            loginData.data.name
                        );
                        dispatchAppData({ infoData: info });
                        let initialDeltaPoints = 0;
                        if (
                            info.data.data[0].coachactivation &&
                            parseInt(info.data.data[0].coachactivation) > 0 &&
                            info.data.data[0].coachPoints &&
                            animatorCameScreen
                        ) {
                            initialDeltaPoints = parseInt(
                                info.data.data[0].coachPoints
                            );
                        } else {
                            initialDeltaPoints = info.data.data[0].pointsdelta;
                        }
                        setTimeout(() => {
                            dispatchAppData({
                                deltaPoints: initialDeltaPoints,
                            });
                        }, 200);
                    });
                }
            }
            dispatchAppData({ animatorDeltaPointsTrigger: false });
        }
    }, [animatorCameScreen]);

    useEffect(() => {
        const timeOutId = setTimeout(
            () => handleSetRFIDInputCallback(keyboardQuery),

            PLAYER_AT_KIOSK_DELAY
        );
        return () => clearTimeout(timeOutId);
    }, [keyboardQuery]);

    // PREPARE PROGRESS BAR DATA
    useEffect(() => {
        if (
            Object.keys(levels).length != 0 &&
            Object.keys(tokens).length != 0 &&
            Object.keys(premiums).length != 0
        ) {
            const barData = prepBarData(levels, tokens, premiums);
            dispatchAppData({ progressBarPrizesData: barData });
            setProgressBarData(barData);
            // dispatchAppData({ progressBarPrizesData: [] });
        }
    }, [levels, premiums, tokens]);

    useEffect(() => {
        let len = RFIDInput.length;
        if (len !== 0 && cardAtKioskResponding === false) {
            scanBand();
        }
    }, [RFIDInput]);

    //NFC SIMULATOR
    useEffect(() => {
        if (RFIDSimulateTrigger != "" && reactToRFID) {
            dispatch({ RFIDInput: RFIDSimulateTrigger });
            dispatchAppData({ RFIDSimulateTrigger: "" });
        }
    }, [RFIDSimulateTrigger]);

    useEffect(() => {
        // react to card
        dispatchAppData({ reactToRFID: true });

        return () => {
            // stop reacting to cards
            dispatchAppData({ reactToRFID: false });
        };
    }, []);

    return {};
};

export default useGKKData;
