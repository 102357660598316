import axios from "axios";

const instance = axios.create({
    baseURL: "https://gokidz.ssd-linuxpl.com/admin/api/v1/",
});

const getLoginData = (kioskId: string) => {
    return {
        unique_key: kioskId,
        password: `Kiosk#${kioskId}GO!`,
        version: "{1.71.0.0}",
        kiosk: "kiosk",
        computerName: "nazwaKomputera",
    };
};

const getAuthHeader = (token: string) => {
    return {
        headers: {
            Authorization: token,
        },
    };
};
/*
const getAuthHopperLogHeader = (token: string) => {
    return {
        Authorization: token,
    };
};
*/

type Response<T> = {
    status: number
    data: T
}

type CardAtKioskResponse = {
    status: string
    message:string
    otherInfo: string
    data: {
        minutesToEnterKiosk: number,
        secondsToEnterKiosk: number,
        pointsToEnterKiosk: number,
        playtime_id: number,
        player_id: string
        CardNumber: string
        group_id: string
        groupName: null,
        pointsForNextToken: string
        TotalPlaytimeMinutes: string
        PlayerNickName: string
        LevelName: string
        PlayerPoints: string
        PlayerTokens:string
        ismastercard: string
        isanimator: string
        id: string
        giveTokens: string
        bonusPoints: number,
        kioskActive: number,
        attractionSuccess: number,
        canEnter: number,
        totalKiosksForAttraction: string
        noOfKiosksCompleted: string
        premium: any
        level_id: string
        playerprofile_id: string
        noofplayers: string
        pointsperplayer: string
        totalpoints: string
    },
    pointsToEnterKiosk:number,
    tokensToEnterKiosk: number
}

const api = {
    login: (kioskId: string) => {
        return instance.post("loginKiosk", getLoginData(kioskId));
    },
    hopperBroken: (token: string, kioskName: string) => {
        return instance.post(`hopperBroken/${kioskName}`, getAuthHeader(token));
    },
    hopperLog: (
        token: string,
        kioskName: string,
        currentPlayerPlaytimeId: string,
        totalTokens: string,
        tokensGiven: string,
        noOfHopperChecks: string
    ) => {
        return instance.post(
            `hopperLog/${kioskName}/${currentPlayerPlaytimeId}/${totalTokens}/${tokensGiven}/${noOfHopperChecks}`,
            {},
            getAuthHeader(token)
        );
    },
    kiosk: (token: string, kioskId: string, tokensPaid: number) => {
        return instance.get(
            `kiosk/${kioskId}/${tokensPaid}`,
            getAuthHeader(token)
        );
    },
    getRewards: (token: string) => {
        return instance.get("rewards", getAuthHeader(token));
    },
    getScreens: (token: string) => {
        return instance.get("screens", getAuthHeader(token));
    },
    getPointINFO: (token: string, pointId: string) => {
        return instance.get(`pointINFO/${pointId}`, getAuthHeader(token));
    },
    cardAtkiosk: (
        token: string,
        confirmationRequired: string,
        kioskNumber: string,
        playcardNumber: string,
        noOfRequestedTokens: string,
        coachMode: string,
        recalcPoints:string
    ) => {
        return instance.get<{},Response<CardAtKioskResponse>>(
            `cardAtKiosk3/${confirmationRequired}/${kioskNumber}/${playcardNumber}/${noOfRequestedTokens}/${coachMode}/${recalcPoints}`,
            getAuthHeader(token)
        );
    },
    getPlayerInfo: (token: string, playerId:string) => {
        return instance.get(
            `playerInfoDetails/${playerId}`,
            getAuthHeader(token)
        );
    },
    confirmAtKiosk: (
        token: string,
        playtimeId: string,
        noOfTokensGiven: number,
        playerId: string,
        currentCardNumber: string,
        gateWasOpened: number
    ) => {
        return instance.get(
            `confirmCardAtKiosk/${playtimeId}/${noOfTokensGiven}/${playerId}/${currentCardNumber}/${gateWasOpened}`,
            getAuthHeader(token)
        );
    },
    bestResults: (token: string, heroId: string, noOfTopPlayers: number) => {
        return instance.get(
            `bestResults/${heroId}/${noOfTopPlayers}`,
            getAuthHeader(token)
        );
    },
    getTokens: (token: string) => {
        return instance.get("tokens", getAuthHeader(token));
    },
    getLevels: (token: string) => {
        return instance.get("levels", getAuthHeader(token));
    },
    getPremiums: (token: string) => {
        return instance.get("premiums", getAuthHeader(token));
    },
    logout: (token: string, sessionId: string) => {
        return instance.get(`logout/${sessionId}`, getAuthHeader(token));
    },
};

export default api;
